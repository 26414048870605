import axios from "axios";
import React, { useState, useEffect, useRef, useContext } from 'react';
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../assets/animation/loading.json";
import { cookieSetter } from '../../dbFetchFunctions';
import firebase from "../../firebase";
import UrlConfig from "../../utils/ApiConfig";
import { MainContext } from "../../context";
import { Translate } from "react-auto-translate";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
};

const UploadingFile = (props) => {
  const context = useContext(MainContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadShow, setUploadShow] = useState(false);
  const [preview, setPreview] = useState(null);
  const [idToken, setIdToken] = useState(props.idToken);
  const [wid, setWid] = useState(localStorage.getItem("walletid") || props.wid);
  const targetRef = useRef(null);

  const [state, setState] = useState({
    renderSignout: false,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { token, firebaseUser: user } = context;
        const img = user?.photoURL;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [context]);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadShow(true);
    setPreview(URL.createObjectURL(event.target.files[0]));
  };

  const onFileUpload = async () => {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
        duration: 5000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
    });

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("usid", wid);
    try {
      const res = await axios.post(
        UrlConfig.uploadImageAPI,
        formData,
        {
          headers: {
            wid: localStorage.getItem("walletid") || wid,
          },
          withCredentials: true,
          credentials: "include",
        }
      );
      if (res.data) {
        if (res.data.error === "no error" && res.data.success === true) {
          toast.success(<span><Translate>Avatar has been published! 🎉🖼️</Translate></span>, {
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
          setTimeout(function(){
            window.location.reload(false);
          }, 3000);
        } else {
          handleErrors(res.data);
        }
      }
    } catch (err) {
      console.log("Error in log =>", err);
    }
  };

  const handleErrors = async (data) => {
    if (data.error === "missing auth token") {
      // Calling cookieSetter
      const account = localStorage.getItem("walletid");
      await cookieSetter(account, onFileUpload, setIdToken);
    } else if (["invalid cookie", "wallet mismatch", "platform mismatch", "file name is not secure"].includes(data.error)) {
      // Firebase sign out > redirect to login
      firebase
      .auth()
      .signOut()
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          renderSignout: true,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        UrlConfig.cookieSetterAPI,
        {
          params: {
            isLogout: "yes",
            uid: context.firebaseUser.uid,
          },
          headers: {
            idtoken: localStorage.getItem("idtoken"),
            wid: localStorage.getItem("walletid"),
          },
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.error === "no error") {
          firebase
            .auth()
            .signOut()
            .then(() => {
              setState((prevState) => ({
                ...prevState,
                renderSignout: true,
              }));
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log("Error req", error);
      });
      localStorage.removeItem("idtoken");
      localStorage.removeItem("walletid");
      localStorage.removeItem("provider");
      toast.success(<span><Translate>Logged out!</Translate></span> , {
        duration: 4000,
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    } else if (data.error === "not supported file type") {
      toast.error(<span><Translate>This file format is not supported. Please upload only JPG, PNG, BMP, or JPEG files</Translate></span>, {
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
    } else if (data.error.includes("exceeded")) {
      toast.error(<span><Translate>Oops! The file is too large to upload</Translate></span>, {
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
    } else {
      toast.error(<Translate>{data.error}</Translate>, {
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
    }
  };

  return (
    <div>
      <div className="setting-pic">
        <img
          src={preview ? preview : props.imageFromParent}
          alt="user"
          className='rounded-circle'
        />
        <label htmlFor="upload-img">
          <i className="fa fa-camera" aria-hidden="true"></i>
        </label>
        <input
          type="file"
          className="inputfile"
          id="upload-img"
          onChange={onFileChange}
        />
        {uploadShow ? (
          <button className="btn-gold" onClick={onFileUpload}>
            <Translate>Upload!</Translate>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default UploadingFile;
