import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import info from "../../../assets/images/icons/info_30.png";
import Follome from "../../followme/followme";
import Lottie from "react-lottie";
import loading from "../../../assets/animation/main_loader.json";
import animationData from "../../../assets/animation/searching-radius";
import { getUserStats } from "../../../dbFetchFunctions";
import axios from "axios";
import { Translate } from "react-auto-translate";
import UrlConfig from "../../../utils/ApiConfig";
import { cookieSetter } from '../../../dbFetchFunctions';

const loadingLottie = {
  loop: true,
  autoplay: true,
  animationData: loading,
};

const Network = ({ uid }) => {
  const [search, setSearch] = useState("");
  const [idToken, setidToken] = useState(localStorage.getItem("idtoken"));
  const [addFriendId, setAddFriendId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [CONTACTS, setCONTACTS] = useState([]);
  const [suggested, setSuggested] = useState([]);
  const [userStats, setUserStats] = useState({});
  const [Hplayed, setHplayed] = useState("");
  const [Friends, setFriends] = useState("");
  const [Level, setLevel] = useState("");
  const [Games, setGames] = useState("");
  const [Wins, setWins] = useState("");
  const [Losses, setLosses] = useState("");
  const [isFollowing, setIsFollowing] = useState("");
  const [followmeShow, setFollowmeShow] = useState(false);
  const [followId, setFollowId] = useState("");
  const [followNick, setFollowNick] = useState("");
  const [followAvatar, setFollowAvatar] = useState("");
  const [timer, setTimer] = useState(null);
  const [disableBtn, setDisableBtn] = useState("");

  const fetchUserStats = async (Uid) => {
    const fetchedUserStats = await getUserStats(Uid);
    setUserStats((prevUserStats) => ({
      ...prevUserStats,
      [Uid]: fetchedUserStats,
    }));
    setHplayed(fetchedUserStats?.total?.HoursPlayed.toFixed(2) || 0);
    setFriends(fetchedUserStats?.social?.friends || 0);
    setLevel(fetchedUserStats?.total?.Level || 0);
    setGames(fetchedUserStats?.total?.games || 0);
    setWins(fetchedUserStats?.total?.win || 0);
    setLosses(fetchedUserStats?.total?.lose || 0);
  };

  const handleFollowMe = (event) => {
    const res = String(event);
    const [Uid, nickname, resFollowing, avatar] = res.split(",");

    fetchUserStats(Uid);

    setFollowmeShow(!followmeShow);
    setFollowId(Uid);
    setFollowNick(nickname);
    setIsFollowing(resFollowing);
    setFollowAvatar(avatar);
  };

  const followmeClose = () => {
    setFollowmeShow(!followmeShow);
  };
  
  const onKeyUpValue = (event) => {
    const searchValue = event.target.value.trim(); // Remove leading and trailing spaces
    let isValid = true;
    const regex = /[`!@#$%^&*()+=[\]{};':"\\|,.<>/?~]/;
    if (searchValue === "") {
      setErrorMessage("Please type something");
      isValid = false;
    } else if (searchValue === " ") {
      setErrorMessage("Space not allowed");
      isValid = false;
    } else if (searchValue.match(regex)) {
      setErrorMessage("Special characters are not allowed");
      isValid = false;
    } else {
      setErrorMessage("Searching...");
    }

    if (isValid) {
      setSearch(searchValue);
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        axios
          .get(UrlConfig.searchFriends, {
            params: { usid: uid, search: searchValue },
          })
          .then((response) => {
            if (response.data) {
              if (response.data.error !== "no error") {
                setCONTACTS(response.data.search);
                setSuggested(response.data.suggested);
                setErrorMessage("");
              } else if (response.data.error === "missing params") {
                setCONTACTS([]);
                setErrorMessage("");
              } else if (response.data.error == "the first letter must be alphabetical") {
                setErrorMessage("The first letter must be a letter from the alphabet");
              } else if (response.data.error !== "no error") {
                toast.error(<Translate>{response.data.error}</Translate>, {
                  duration: 3000,
                  style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                  },
                });
              }
            }
          })
          .catch((error) => {
            console.log("Error req", error);
          });
      }, 1000);
      setTimer(newTimer);
    }
  };

  const refreshCookieAlert = () => {
    toast.success(<span><Translate>Oops! Something went wrong</Translate></span>, {
      duration: 4000,
      icon: '⚠️',
      style: {
        maxWidth: 800,
        fontSize: "14px",
        borderRadius: "15px",
      },
    });
  }


  const handleAddFriend = async (event) => {
    const res = String(event);
    const [fId, Fname] = res.split(",");
    setDisableBtn(fId);
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      duration: 4000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    try {
      const response = await axios.get(UrlConfig.sendFriendRequest, {
        params: { frId: fId },
        headers: { wid: localStorage.getItem("walletid") },
        withCredentials: true,
        credentials: "include",
      });
      setDisableBtn("");
      if (response.data) {
        if (response.data.error === "no error") {
          toast.success(<span><Translate>Friend request sent!</Translate></span>, {
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
        } else if (response.data.error === "missing auth token") {
          const account = localStorage.getItem("walletid");
          await cookieSetter(account, refreshCookieAlert, setidToken);
        } else if (response.data.error === "Already sent friend request") {
          toast.error(
            <span>
              <Translate>Friend request has already been sent to </Translate>
              @{Fname}
              {response.data.client_PopUp}
            </span>,
            {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            }
          );
        } else if (response.data.error === "Already Friends") {
          toast.error(
            <span>
              <Translate>You are already friends with </Translate>
              @{Fname}
              <Translate>.</Translate>
            </span>,
            {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            }
          );
        } else if (response.data.error.includes("Token expired")) {
            window.location.reload();
        } else if (response.data.error !== "no error") {
          toast.error(<Translate>{response.data.error}</Translate>, {
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
        }
      } 
    } catch (error) {
      if (error) {
        console.log("err", error);
        toast.error(<Translate>{error}</Translate>, {
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        });
      }
      setDisableBtn("");
    }
  };
  

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div className="holder">
      <div>
        <Modal
          show={followmeShow}
          onHide={followmeClose}
          centered
          className="friends-popup" >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Follome
              avatar={followAvatar}
              followId={followId}
              nick={followNick}
              isFollowing={isFollowing}
              Hplayed={Hplayed}
              Friends={Friends}
              Level={Level}
              Games={Games}
              Wins={Wins}
              Losses={Losses}
            />
          </Modal.Body>
        </Modal>
      </div>
      <div className="friends-search search-network">
        <h5 className="search-network-h"><Translate>Find New Friends</Translate></h5>
        <div className="fri-searchbar">
          <input
            id="mySearchInput"
            className="form-control"
            type="text"
            name="search"
            autoComplete="off"
            placeholder="Search by name.."
            onKeyUp={onKeyUpValue}
          />
        </div>
        {errorMessage === "Searching..." ? (
          <div className="coin-loading">
            <div>
              <Lottie options={defaultOptions} height={150} width={150} />
            </div>
          </div>
        ) : (
          <small style={{ color: "#8461dc" }}><Translate>{errorMessage}</Translate></small>
        )}
      </div>

      <ul className="matched-result">
        {search && errorMessage !== "Searching..." && <small className="search-text">Search result</small>}
        {CONTACTS &&
          CONTACTS.map((f, index) => (
            <li className="contactNew" key={index}>
              <img
                style={{ cursor: "pointer", height: "110px", objectFit: "cover" }}
                src={f.image}
                alt="img"
                onClick={() => handleFollowMe([f.uid, f.name, f.isFollowing, f.image])}
              />
              <span style={{ padding: "10px" }}>
                <img
                  className="cursor"
                  onClick={() => handleFollowMe([f.uid, f.name, f.isFollowing, f.image])}
                  src={info}
                  alt="info"
                />{" "}
                {f.name}
              </span>{" "}
              <div className="network-btn">
                <Button
                  className={`btn-gold ${disableBtn === f.uid ? "disable-btn" : ""}`}
                  onClick={() => handleAddFriend([f.uid, f.name])} >
                  <Translate>Add friend</Translate>
                </Button>
              </div>
            </li>
          ))}
      </ul>

      <ul>
        {suggested.length ? <small className="search-text">Suggestions</small> : ""}
        {suggested && 
          suggested.map((f, index) => (
            <li className="contactNew" key={index}>
              <img
                style={{ cursor: "pointer", height: "110px", objectFit: "cover" }}
                src={f.image}
                onClick={() => handleFollowMe([f.uid, f.name, f.isFollowing, f.image])}
                alt="img"
              />
              <span style={{ padding: "10px" }}
              onClick={() => handleFollowMe([f.uid, f.name, f.isFollowing, f.image])} 
              className="cursor" >
                <img
                  className="cursor"
                  onClick={() => handleFollowMe([f.uid, f.name, f.isFollowing, f.image])}
                  src={info}
                  alt="info"
                />{" "}
                {f.name}
              </span>{" "}
              <div className="network-btn">
                <Button
                  className={`btn-gold ${disableBtn === f.uid ? "disable-btn" : ""}`}
                  onClick={() => handleAddFriend([f.uid, f.name])} >
                  <Translate>Add friend</Translate>
                </Button>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Network;