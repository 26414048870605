import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./header.css";
import axios from "axios";
import firebase from "../../firebase";
import { useState } from "react";
import { MainContext } from "../../context";
import { Translate } from "react-auto-translate";
import Bell from "../bell";
import UrlConfig from '../../utils/ApiConfig';
import logo from "../../assets/images/header/logo-new.png";
import logop from "../../assets/images/header/polygon.svg";
import helpicon from "../../assets/images/register/help.png";

const Header = () => {
  const context = useContext(MainContext);
  const [uid] = useState(context?.firebaseUser?.uid);
  const [token] = useState(context.token);
  const [notification, setNotification] = useState([]);
  const [unreadCount, setunreadCount] = useState();
  const history = useHistory();
  const [stickyHeader, setStickyHeader] = useState(false);
  const [ip, setIp] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const { language, setLanguage } = context;

   // Geolocation function
   const fetchGeoLocation = async () => {
    try {
      const res = await axios.get(UrlConfig.geoLocationUrl);
      const values = res.data.split("\n");
      let obj = {};
      values.forEach((value) => {
        obj[value.split("=")[0]] = value.split("=")[1];
      });
      let data = { IPv4: obj.ip, country_code: obj.loc };
      setIp(data.IPv4);
      setCountryCode(data.country_code);
      // Set language based on country code
      handleCountryCode(data.country_code);
    } catch (error) {
      console.error("Error fetching geolocation data:", error);
    }
  };

   // Runs geolocation only if pathname === "/" and there is no lang on !localStorage
   useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/" && !localStorage.getItem("language")) {
      fetchGeoLocation();
    }
  }, []);

   // Language mapping based on country code
   const handleCountryCode = (countryCode) => {
    let languageKey = "en"; // Default language is English

    switch (countryCode) {
      case "IN":
        languageKey = "hi";
      break;
      case "PO": // Portugal
      case "AO": // Angola
      case "MZ": // Mozambique
      case "PU": // Guinea-Bissau
        languageKey = "pt-PT";
      break;
      case "BR": // Brazil
        languageKey = "pt-BR";
      break;
      case "SP": // Spain
      case "MX": // Mexico
      case "AR": // Argentina
      case "CO": // Colombia
      case "CL": // Chile
      case "PE": // Peru
      case "VE": // Venezuela
      case "EC": // Ecuador
      case "UY": // Uruguay
        languageKey = "es";
      break;
      case "CN": // China
        languageKey = "zh-CN";
      break;
      case "RU": // Russia
        languageKey = "ru";
      break;
      case "SA": // Saudi Arabia
      case "JO": // Jordan
      case "QA": // Qatar
      case "MA": // Morocco
      case "TN": // Tunisia
      case "DZ": // Algeria
        languageKey = "ar";
      break;
      case "TH": // Thailand
        languageKey = "th";
      break;
      case "ID": // Indonesia
        languageKey = "id";
      break;
      case "SN": // Singapore
        languageKey = "sg";
      break;
      default:
        languageKey = "en";
      break;
    }

    setLanguage(languageKey);
    localStorage.setItem("language", languageKey);
  };

  const watchScroll = () => {
    if (window.scrollY > 60) {
      setStickyHeader(true);
    } else {
      setStickyHeader(false);
    }
  };

  useEffect(() => {
    if (window !== undefined && window.screen.width >= 0) {
      window.addEventListener("scroll", watchScroll);
    }
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);

  const getNotification = async (token) => {
    const res = await axios.get("https://fetch-noti-t3e66zpola-uc.a.run.app/", {
      headers: {
        idToken: token,
      },
    });
    if(res.data.error === "user must login"){
      window.location.reload();
    }
    if (res.data.success) {
      context.setNotification(res.data.notiArray);
      const count = res.data.notiArray.reduce((prev, curr) => {
        return prev + !curr.isRead;
      }, 0);
      setNotification(res.data.notiArray);
      setunreadCount(count);
    }
  };

  useEffect(() => {
	let unsubscribe;
    const getFeedListener = async () => {
      const db = firebase.firestore();
      if (!uid) return;
      unsubscribe = db.collection(`${process.env.REACT_APP_DB_PREFIX}messanger`)
        .doc(uid)
        .collection("noti")
        .onSnapshot(async (snapshot) => {
          if (snapshot.docs.length < 1) {
            return 0;
          }
          getNotification(token);
        });
    };
    getFeedListener();
	return ()=>{
		if(unsubscribe){
			unsubscribe()
		  }
    }
  }, [uid, token]);

  const pathname = window.location.pathname;
  const splitLocation = pathname.split("/");
  const xtkn = localStorage.getItem("xtkn");

  if (pathname === "/") {
    return (
        <div
          className={`header help-center-header ${
            stickyHeader ? "sticky-header" : ""
          }`} >
          <div className="header-top-area">
            <div className="container">
              <div className="header-wrapper">
                <h1 className="live-gaming-h">
                    <span className="h1-red"><Translate>LIVE</Translate> &#x2022;</span> <small><Translate>Poker | Sports Betting</Translate></small>
                  </h1>
                  <div>
                    <div className="language-selector">
                        <select
                          className="lang-select"
                          onChange={(e) => {
                            setLanguage(e.target.value);
                            localStorage.setItem("language", e.target.value);
                          }}
                          value={language} // Select the language
                          >
                        <option value="en">English</option>
                        <option value="hi">Hindi</option>
                        <option value="pt-BR">Portuguese</option>
                        <option value="es">Spanish</option>
                        <option value="zh-CN">Chinese</option>
                        <option value="ru">Russian</option>
                        <option value="ar">Arabic</option>
                        <option value="th">Thailand</option>
                        <option value="id">Indonesia</option>
                        <option value="sg">Singapore</option>
                      </select>
                    <div id="google_translate_element" style={{ display: "none" }}></div>
                    </div>
                    
                  </div>
              </div>
            </div>
          </div>
          <div className="help-desk-icon">
          <span
            onClick={() => {
              history.push("/help-center");
            }}
            role="presentation">
            <img src={helpicon} alt="help" />
          </span>
        </div>
        </div>
      );
  }
  
  if (xtkn === null && pathname === "/help-center") {
    return (
      <div
        className={`header help-center-header ${
          stickyHeader ? "sticky-header" : ""
        }`} >
        <div className="header-top-area">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                  <img src={logop} alt="logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="help-desk-icon">
          <span
            onClick={() => {
              history.push("/help-center");
            }}
            role="presentation" >
            <img src={helpicon} alt="help" />
          </span>
        </div>
      </div>
    );
  }

  const getBellIconAndLogin = () => {
    if (pathname === "/profile") {
      return (
        <Bell
          uid={uid}
          idToken={token}
          notification={notification}
          unreadCount={unreadCount}
        />
      );
    }
    return "";
  };

  return (
    <div className={`header ${stickyHeader ? "sticky-header" : ""}`}>
      <div className="container">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          style={{ color: "#191b25" }} >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {" "}
            <div className="logo">
                <img src={logo} alt="logo" className="logo-profile" /> <small className="logo-text-profile">WEJE</small>
            </div>{" "}
            <div className="header-top-login after-login">
              <ul>
                <li className=" wow animate__animated animate__slideInUp">
                  <span
                    className={
                      splitLocation[1] === "profile" ? "activeheader" : "false"
                    }
                    role="presentation">
                    <Link to="/profile">
                      <i className="las la-home"></i>
                      <p><Translate>GAMES</Translate></p>
                    </Link>
                  </span>
                </li>

                <li className=" wow animate__animated animate__slideInUp">
                  <span
                    className={
                      splitLocation[1] === "friends" ? "activeheader" : "false"
                    }
                    role="presentation" >
                    <Link to="/friends">
                      <i className="las la-user-friends"></i>
                      <p><Translate>Friends</Translate></p>
                    </Link>
                  </span>
                </li>
                
                {/* <li className=" wow animate__animated animate__slideInUp">
                  <span
                    className={
                      splitLocation[1] === "feed" ? "activeheader" : "false"
                    }
                    role="presentation" >
                    <Link to="/feed">
					              <i className="las la-comments"></i>
                        <p>FEED</p>
                    </Link>
                  </span>
                </li> */}

                  {(pathname !== '/') && (
                    <li className=" wow animate__animated animate__slideInUp">
                    <span
                      className={
                      splitLocation[1] === "settings" ? "activeheader" : "false"
                      }
                      role="presentation">
                      <Link to="/settings">
                      <i className="las la-cog"></i>
                      <p><Translate>Settings</Translate></p>
                      </Link>
                    </span>
                    </li>
                  )}
              </ul>
            </div>
            <div className="bell-section">
			  {getBellIconAndLogin()}
			</div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
