import React, { useState, useEffect, useRef, useContext } from 'react';
import Layout from "../layout/layout";
import { Button, Modal, Form, InputGroup, Overlay, Tooltip, Spinner } from "react-bootstrap";
import firebase, { firebaseApp } from "../../firebase";
import toast from "react-hot-toast";
import Lottie from 'react-lottie';
import loading from "../../assets/animation/main_loader.json";
import TransactionList from "../transaction/transaction";
import UrlConfig from "../../utils/ApiConfig";
import "./settings.css";
import axios from "axios";
import UploadingFile from "./uploadingFile";
// import DeleteUser from "./deleteUser";
import Share from "../share/share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copyicon from "../../assets/images/profile/copyicon.svg";
import PrivacyPolicy from "../privacy-policy";
import TermsOfUse from "../terms-of-use";
import { isSafari, isIOS, browserName } from "react-device-detect";
import { MainContext } from "../../context";
import { cookieSetter } from '../../dbFetchFunctions';
import { Translate } from "react-auto-translate";

// New ux ui
import Level100 from '../../assets/animation/Level100.png';
import Level100GIF from '../../assets/animation/gifs/Level-up.gif';
import Games100 from '../../assets/animation/Games100.png';
import GameGIF from '../../assets/animation/gifs/Games-gif.gif';
import Wins100 from '../../assets/animation/Wins100.png';
import WinsGif from '../../assets/animation/gifs/Wins-gif.gif';
import Lost100 from '../../assets/animation/Lost100.png';
import LostGif from '../../assets/animation/gifs/Lost-gif.gif';
import Friends100 from '../../assets/animation/Friends100.png';
import FriendsGif from '../../assets/animation/gifs/Friends-gif.gif';
import Time100 from '../../assets/animation/Time100.png';
import TimeGif from '../../assets/animation/gifs/Time-gif.gif';
import Matic100 from "../../assets/images/header/logo-new.svg";
import Following100 from '../../assets/animation/Following100.png';
import FollowingGIF from '../../assets/animation/gifs/Following-gif.gif';
import Followers100 from '../../assets/animation/Followers100.png';
import FollowersGIF from '../../assets/animation/gifs/Followers-gif.gif';
import Transaction100 from '../../assets/animation/Transaction100.png';
import TransactionGIF from '../../assets/animation/gifs/Transaction-gif.gif';

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };


const Settings = () => {
  const context = useContext(MainContext);
  const { language, setLanguage } = context;
  const [idToken, setidToken] = useState(localStorage.getItem("idtoken"));
  const [state, setState] = useState({
    nickSpinner: false,
    signedin: true,
    renderSignout: false,
    isSignoutClicked: false,
    pending: false,
    uid: null,
    isEditing: false,
    nick: 'adiKing',
    originalNick: '',
    image: "",
    NvalidLogins: 0,
    delete: "",
    addFriendsShow: false,
    showCopyTooltip: false,
    i_sound: localStorage.getItem("i_sound") === "true" ? true : false,
    i_vibrate: localStorage.getItem("i_vibrate") === "true" ? true : false,
    i_notification: window?.Notification?.permission === "granted",
    stats: {
      maticwins: 1,
      maticlost: 1,
      games: 1,
      wins: 1,
      losses: 1,
      winLoseRation: 1,
      Hplayed: 1,
      friends: 1,
      followers: 1,
      following: 1,
      Level: 1,
    },
    transaction: false,
    PrivacyPolicyShow: false,
    TermsOfUseShow: false,
    SignOutShow: false,

    //Animations isHovered
    isLevelHovered: false,
    isGamesHovered: false,
    isWinsHovered: false,
    isLostHovered: false,
    isFriendsHovered: false,
    isTimeHovered: false,
    isRatioHovered: false,
    isFollowingHovered: false,
    isFollowersHovered: false,
    isTransactionHovered: false,
  });

  const targetRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { token, firebaseUser: user, baseStats } = context;
        const img = user?.photoURL;

        if (baseStats) {
          setState((prevState) => ({
            ...prevState,
            idToken: token,
            pending: false,
            signedin: true,
            uid: user.uid,
            nick: user.displayName || baseStats.userDoc.nickname,
            originalNick: user.displayName || baseStats.userDoc.nickname,
            image: img || baseStats.userDoc.photoURL,
            NvalidLogins: prevState.NvalidLogins + 1,
            delete: baseStats.userDoc.deletingAccount,
            stats: {
              ...prevState.stats,
              maticwins: baseStats.total.winCoins.toFixed(2),
              maticlost: baseStats.total.loseCoins.toFixed(2),
              games: baseStats.total.games.toLocaleString(),
              wins: baseStats.total.win.toLocaleString(),
              losses: baseStats.total.lose.toLocaleString(),
              winLoseRation: baseStats.total.wl_ratio,
              Hplayed: baseStats.total.HoursPlayed.toFixed(2),
              friends: baseStats.social.friends.toLocaleString(),
              followers: baseStats.social.followers.toLocaleString(),
              following: baseStats.social.following.toLocaleString(),
              Level: baseStats.total.Level.toLocaleString(),
            },
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    toggleTransaction();
  }, [context]);


    const handleChangeNotification = async () => {
      if (
        !state.i_notification &&
        !isIOS &&
        !isSafari &&
        browserName !== 'Firefox' &&
        window?.Notification?.permission !== 'denied'
      ) {
        try {
          let messaging = firebase.messaging();
          const data = await messaging.getToken({
            vapidKey:
              'BBuN7Nx87ktleMg-L2LvCoLRvmsMsssnAfHbsDnDzUwe4AqchcwRobHr0qmHMWtFDKatd9oK5Oh6zFlnM9Gf39Y',
          });

          if (data) {
            await axios.get(
              'https://push-token-init-t3e66zpola-uc.a.run.app/addUid',
              {
                headers: {
                  Idtoken: state.idToken,
                  fmctoken: data,
                },
              }
            );
            localStorage.setItem('fmcToken', data);
            localStorage.setItem('pushNotification', 'granted');
          }
        } catch (error) {
          console.error('Error handling notification:', error);
        }
      } else if (state.i_notification) {
        await axios.get(
          'https://push-token-init-t3e66zpola-uc.a.run.app/disable',
          {
            headers: {
              Idtoken: state.idToken,
              fmctoken: null,
            },
          }
        );
        localStorage.removeItem('fmcToken');
      }
      setState((prevState) => ({
        ...prevState,
        i_notification: !prevState.i_notification,
      }));
    };
  

  const addFriends = () => {
    setState((prevState) => ({
      ...prevState,
      addFriendsShow: !prevState.addFriendsShow,
    }));
  };

  const handleContentChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      nick: e.target.value,
    }));
  };

  const handleEditClick = () => {
    setState((prevState) => ({
      ...prevState,
      isEditing: !prevState.isEditing,
    }));

  };

  const handleEndEditingClick = async () => {
    if (state.nick === state.originalNick) { 
      toast.error(<span><Translate>It looks like you haven't changed your nickname</Translate></span>, {
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
      return;
    }

    toast.custom(
      <Lottie options={loadingLottie} width={100} height={100} />, {
        duration: 2000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
      }
    );
    try {
      setState((prevState) => ({
        ...prevState,
        nickSpinner: !prevState.nickSpinner,
      }));
    const res = await axios.get(UrlConfig.changeNick, {
        params: {
          nick: state.nick,
        },
        headers: {
          wid: localStorage.getItem("walletid"),
        },
        withCredentials: true,
        credentials: 'include',
      }
    );
    if (res.data.success === true) {
      toast.success(<span><Translate>Nickname updated</Translate></span>, {
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
    });
    setState((prevState) => ({
      ...prevState,
      nickSpinner: !prevState.nickSpinner,
    }));
    setTimeout(function(){
      window.location.reload(false);
    }, 3000);
    } else if (res.data.error === "missing auth token") {
      const account = localStorage.getItem("walletid");
      await cookieSetter(account , handleEndEditingClick, setidToken);
    } else {
      toast.error(<Translate>{res.data.error}</Translate> , {
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
    }
    setState((prevState) => ({
      ...prevState,
      isEditing: !prevState.isEditing,
    }));
    } catch(e) {
      if (e.message == "Request failed with status code 422") {
        const account = localStorage.getItem("walletid");
        await cookieSetter(account, handleEndEditingClick, setidToken);
      }
    }
  };

  const handleMouseEnter = (param) => {
    if (param === 'level') {
      setState((prevState) => ({
        ...prevState,
        isLevelHovered: true,
      }));
    } if (param === 'games') {
        setState((prevState) => ({
          ...prevState,
          isGamesHovered: true,
        }));
    } if (param === 'wins') {
        setState((prevState) => ({
          ...prevState,
          isWinsHovered: true,
        }));
    } if (param === 'lost') {
        setState((prevState) => ({
          ...prevState,
          isLostHovered: true,
        }));
    } if (param === 'friends') {
        setState((prevState) => ({
          ...prevState,
          isFriendsHovered: true,
        }));
    } if (param === 'time') {
        setState((prevState) => ({
          ...prevState,
          isTimeHovered: true,
        }));
    } if (param === 'ratio') {
        setState((prevState) => ({
          ...prevState,
          isRatioHovered: true,
        }));
    } if(param === "following"){
      setState((prevState) => ({
        ...prevState,
        isFollowingHovered: true,
      }));
    }
    if(param === "followers"){
      setState((prevState) => ({
        ...prevState,
        isFollowersHovered: true,
      }));
    }
  };

  const handleMouseLeave = (param) => {
    if (param === 'level') {
      setState((prevState) => ({
        ...prevState,
        isLevelHovered: false,
      }));
    } if (param === 'games') {
        setState((prevState) => ({
          ...prevState,
          isGamesHovered: false,
        }));
    } if (param === 'wins') {
        setState((prevState) => ({
          ...prevState,
          isWinsHovered: false,
        }));
    } if (param === 'lost') {
        setState((prevState) => ({
          ...prevState,
          isLostHovered: false,
        }));
    } if (param === 'friends') {
        setState((prevState) => ({
          ...prevState,
          isFriendsHovered: false,
        }));
    } if (param === 'time') {
        setState((prevState) => ({
          ...prevState,
          isTimeHovered: false,
        }));
    } if (param === 'ratio') {
        setState((prevState) => ({
          ...prevState,
          isRatioHovered: false,
        }));
    } if(param === "following"){
      setState((prevState) => ({
        ...prevState,
        isFollowingHovered: false,
      }));
    }
    if(param === "followers"){
      setState((prevState) => ({
        ...prevState,
        isFollowersHovered: false,
      }));
    }
  };


  const toggleTransaction = () => {
    setState((prevState) => ({
      ...prevState,
      transaction: !prevState.transaction,
    }));
  };

  const handleChangeSound = () => {
    setState((prevState) => {
      const newSoundState = !prevState.i_sound;
      localStorage.setItem("i_sound", String(newSoundState));
      return { ...prevState, i_sound: newSoundState };
    });
  };

  const handleChangeVibrate = () => {
    if (!state.i_vibrate) {
      navigator.vibrate([
        500, 250, 500, 250, 500, 250, 500, 250, 500, 250, 500,
      ]);
    } else {
      navigator.vibrate(0);
    }

    setState((prevState) => {
      const newVibrateState = !prevState.i_vibrate;
      localStorage.setItem("i_vibrate", String(newVibrateState));
      return { ...prevState, i_vibrate: newVibrateState };
    });
  };

  const handlePrivacyPolicy = () => {
    setState((prevState) => ({
      ...prevState,
      PrivacyPolicyShow: true,
    }));
  };

  const handleTermsOfUse = () => {
    setState((prevState) => ({
      ...prevState,
      TermsOfUseShow: true,
    }));
  };

  const handleSignOut = () => {
    setState((prevState) => ({
      ...prevState,
      SignOutShow: true,
    }));
  };

  const handleCloseAddFriends = () => {
    setState((prevState) => ({
      ...prevState,
      addFriendsShow: !prevState.addFriendsShow,
    }));
  };

  const handleCopy = () => {
    setState((prevState) => ({
      ...prevState,
      showCopyTooltip: true,
    }));

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showCopyTooltip: false,
      }));
    }, 2000);

    toast.success(<span><Translate>Copied!</Translate></span>, {
      icon: "✔️",
      position: "top-right",
      style: {
        maxWidth: 800,
        fontSize: "14px",
        borderRadius: "15px",
      },
    });
  };

  const handlePrivacyPolicyClose = () => {
    setState((prevState) => ({
      ...prevState,
      PrivacyPolicyShow: !prevState.PrivacyPolicyShow,
    }));
  };

  const handleTermsOfUseClose = () => {
    setState((prevState) => ({
      ...prevState,
      TermsOfUseShow: !prevState.TermsOfUseShow,
    }));
  };

  const handleSignOutClose = () => {
    if (!state.isSignoutClicked) {
      setState((prevState) => ({
        ...prevState,
        SignOutShow: !prevState.SignOutShow,
      }));
    }
  };

  const doSignout = () => {
    setState((prevState) => ({
      ...prevState,
      isSignoutClicked: true,
    }));

    toast.custom (
      <Lottie options={loadingLottie} width={100} height={100} />, {
        duration: 2000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
      }
    );

    firebase
      .auth()
      .signOut()
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          renderSignout: true,
        }));
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        UrlConfig.cookieSetterAPI,
        {
          params: {
            isLogout: "yes",
            uid: context.firebaseUser.uid,
          },
          headers: {
            idtoken: localStorage.getItem("idtoken"),
            wid: localStorage.getItem("walletid"),
          },
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.error === "no error") {
      localStorage.removeItem("idtoken");
      localStorage.removeItem("walletid");
      localStorage.removeItem("provider");
      toast.success(<span><Translate>Logged out!</Translate></span> , {
        duration: 4000,
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
          });
        }
      })
      .catch((error) => {
        console.log("Error req", error);
      });
  };



  return (
    <Layout>
        <div className="settings">
          <div className="container">
            <div className="row">
              <div className="col-md-10 m-auto div-top-settings">
                <div className="settings-content">
                  <h3><Translate>Settings</Translate></h3>
                  <div className="language-selector">
                    <select className="lang-select" onChange={(e) => {
                      setLanguage(e.target.value);
                      localStorage.setItem("language", e.target.value);
                    }} defaultValue={localStorage.getItem("language") || "en"}>
                      <option value="en">English</option>
                      <option value="hi">Hindi</option>
                      <option value="pt-BR">Portuguese</option>
                      <option value="es">Spanish</option>
                      <option value="zh-CN">Chinese</option>
                      <option value="ru">Russian</option>
                      <option value="ar">Arabic</option>
                      <option value="th">Thailand</option>
                      <option value="id">Indonesia</option>
                      <option value="sg">Singapore</option>
                    </select>
                  <div id="google_translate_element" style={{ display: "none" }}></div>
                </div>

                  <div className="settings-container">
                    <div className="setting-info">
                      <UploadingFile
                        useridFromParent={state.uid}
                        imageFromParent={state.image}
                        idToken={idToken} 
                        wid={localStorage.getItem("walletid")}
                        />
    
                        <div className="main-edit">
                          <div className="editname-div">
                            <input
                                value={state.nick}
                                type="text"
                                name="name"
                                readOnly={!state.isEditing}
                                onChange={handleContentChange}
                                autoComplete="off"
                                contentEditable={state.isEditing}
                                className={"before-editing " + (state.isEditing ? 'start-editing' : 'end-editing')}
                            />
                            <div className="edit-buttons">
                              <button onClick={handleEditClick} id="edit-button" className="edit-btn">
                                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                              </button>
                              <button onClick={handleEndEditingClick} id="end-editing" className={state.isEditing ? 'start-btn' : 'end-btn'} >
                                  {state.nickSpinner ? <Spinner animation="border" /> : <Translate>Save</Translate>}
                              </button>
                            </div>
                          </div>                        
                      </div>

                      <ul>
                        <li>
                          <h6 className="mb-0">
                          <img
                            src={Matic100}
                            alt="Admin"
                            className="matic-fix" />
                           <b className="matic-value">
                           {state.stats?.maticwins}
                            </b>
                            <b className="i-text">POL<span style={{ fontSize: "10px" }}>(MATIC)</span></b> <i className="fa fa-arrow-up" aria-hidden="true" style={{ color: "#32CD32" }}></i>
                          </h6>
                        </li>

                        <li>
                          <h6 className="mb-0">
                          <img
                            src={Matic100}
                            alt="Admin"
                            className="matic-fix" />
                          <b className="matic-value">
                          {state.stats?.maticlost}
                          </b>
                          <b className="i-text">POL<span style={{ fontSize: "10px" }}>(MATIC)</span></b> <i className="fa fa-arrow-down" aria-hidden="true" style={{ color: "#FF0000" }}></i>
                          </h6>
                        </li>

                        <li onMouseEnter={() => handleMouseEnter("games")}
                            onMouseLeave={() => handleMouseLeave("games")} >
                          <h6 className="mb-0">
                            <img
                            src={state.isGamesHovered ? GameGIF :  Games100}
                            alt="Admin"
                            className="logo-fix" />
                            <b className="i-text"><Translate>Games</Translate></b>
                          </h6>
                          <span className="text-white">
                            {state.stats?.games}
                          </span>
                        </li>

                        <li onMouseEnter={() => handleMouseEnter("wins")}
                            onMouseLeave={() => handleMouseLeave("wins")} >
                          <h6 className="mb-0">
                          <img
                            src={state.isWinsHovered ? WinsGif :  Wins100}
                            alt="Admin"
                            className="logo-fix" />
                            <b className="i-text"><Translate>Wins</Translate></b>
                          </h6>
                          <span className="text-white">
                            {state.stats?.wins}
                          </span>
                        </li>

                        <li onMouseEnter={() => handleMouseEnter("lost")}
                            onMouseLeave={() => handleMouseLeave("lost")} >
                          <h6 className="mb-0">
                          <img
                            src={state.isLostHovered ? LostGif :  Lost100}
                            alt="Admin"
                            className="logo-fix" />
                            <b className="i-text"><Translate>Losses</Translate></b>
                          </h6>
                          <span className="text-white">
                            {state.stats?.losses}
                          </span>
                        </li>

                        <li onMouseEnter={() => handleMouseEnter("friends")}
                            onMouseLeave={() => handleMouseLeave("friends")} >
                          <h6 className="mb-0">
                          <img
                            src={state.isFriendsHovered ? FriendsGif :  Friends100}
                            alt="Admin"
                            className="logo-fix" />
                            <b className="i-text"><Translate>Friends</Translate></b>
                          </h6>
                          <span className="text-white">
                            {state.stats?.friends}
                          </span>
                        </li>

                        <li onMouseEnter={() => handleMouseEnter("followers")}
                            onMouseLeave={() => handleMouseLeave("followers")} >
                          <h6 className="mb-0">
                          <img
                            src={state.isFollowersHovered ? FollowersGIF :  Followers100}
                            alt="Admin"
                            className="logo-fix" />
                            <b className="i-text"><Translate>Followers</Translate></b>
                          </h6>
                          <span className="text-white">
                            {state.stats?.followers}
                          </span>
                        </li>

                        <li onMouseEnter={() => handleMouseEnter("following")}
                            onMouseLeave={() => handleMouseLeave("following")} >
                          <h6 className="mb-0">
                          <img
                            src={state.isFollowingHovered ? FollowingGIF :  Following100}
                            alt="Admin"
                            className="logo-fix" />
                            <b className="i-text"><Translate>Following</Translate></b>
                          </h6>
                          <span className="text-white">
                            {state.stats?.following}
                          </span>
                        </li>

                        <li onMouseEnter={() => handleMouseEnter("time")}
                            onMouseLeave={() => handleMouseLeave("time")} >
                          <h6 className="mb-0">
                          <img
                            src={state.isTimeHovered ? TimeGif :  Time100}
                            alt="Admin"
                            className="logo-fix" />
                            <b className="i-text"><Translate>Playing hours</Translate></b>
                          </h6>
                          <span className="text-white">
                            {state.stats?.Hplayed}
                          </span>
                        </li>

                        <li onMouseEnter={() => handleMouseEnter("level")}
                            onMouseLeave={() => handleMouseLeave("level")} >
                          <h6 className="mb-0">
                          <img
                            src={state.isLevelHovered ? Level100GIF :  Level100}
                            alt="Admin"
                            className="logo-fix" />
                            <b className="i-text"><Translate>Level</Translate></b>
                          </h6>
                          <span className="text-white">
                            <b style={{ color: "#ffffff" }}>
                              {state.stats?.Level}
                            </b>
                          </span>
                        </li>
                      </ul>

                      <div className="header-top-login transactionBTN" onMouseEnter={() => handleMouseEnter("transaction")}
                            onMouseLeave={() => handleMouseLeave("transaction")} >
                        <ul className="transaction-ul">
                          <li className="animate__slideInUp">
                            <span
                              onClick={toggleTransaction}
                              role="presentation" 
                              className="presentation-transaction ">
                                 <img
                                    src={state.isTransactionHovered ? TransactionGIF :  Transaction100}
                                    alt="Admin"
                                    className="logo-fix transacion-icon" />
                              <b className="i-text"><Translate>Transactions</Translate></b>
                            </span>
                          </li>
                        </ul>
                      </div>

                      {state.transaction ? (
                        <TransactionList usid={state.uid} />
                      ) : null}

                      <div className="radio-buttons">
                        <div className="radio-box">
                        <Translate>Notifications</Translate>
                          <label className="switch">
                            <input
                              type="checkbox"
                              defaultChecked={state.i_notification}
                              onChange={handleChangeNotification} />
                            <span className="slider round"></span>
                          </label>
                        </div>

                        <div className="radio-box">
                        <Translate>Sound</Translate>
                          <label className="switch">
                            <input
                              type="checkbox"
                              defaultChecked={state.i_sound}
                              onChange={handleChangeSound} />
                            <span className="slider round"></span>
                          </label>
                        </div>

                        <div className="radio-box">
                        <Translate>Vibrate</Translate>
                          <label className="switch">
                            <input
                              type="checkbox"
                              defaultChecked={state.i_vibrate}
                              onChange={handleChangeVibrate} />
                            <span className="slider round"></span>
                          </label>
                        </div>

                      </div>
                      <div className="link-description">
                        <div className="link-box">
                          <span
                          onClick={addFriends}><Translate>Share us</Translate></span>
                        </div>
                        <div className="link-box">
                          <span
                            role="presentation"
                            onClick={handlePrivacyPolicy} >
                            <Translate>Privacy policy</Translate> <i className="fa fa-info-circle" />
                          </span>
                        </div>
                        <div className="link-box">
                          <span
                            role="presentation"
                            onClick={handleTermsOfUse} >
                            <Translate>Terms of use</Translate> <i className="fa fa-info-circle" />
                          </span>
                        </div>
                      </div>

                      {/* <div className="contact-us">
                        <DeleteUser
                          uid={this.state.uid}
                          idToken={this.state.idToken}
                          status={this.state.delete}
                        />
                      </div> */}

                      <div className="contact-us">
                        <div className="contact">
                          <small><Translate>Contact us:</Translate></small> <br></br>
                          <span className="text-secondary">
                            support@weje.com
                          </span>
                        </div>
                      </div>
                      <Button
                        className="btn-gold sign-out-btn"
                        onClick={handleSignOut} >
                        <Translate>Sign out</Translate>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={state.addFriendsShow}
          onHide={handleCloseAddFriends}
          centered
          className="invite-friends-popup" >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="help-h4"><Translate>Help Us Grow</Translate></h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="invite-friends-text">
              <p>
                <span><Translate>Invite your friends to join our community!</Translate></span>
              </p>
            </div>
            <div className="invite-friends-type">
              <Share shareuserId={state.uid} />
            </div>
            <div className="share-copy-link">
              <InputGroup>
                <Form.Control
                  placeholder="https://playerslounge.co/?r=gamer1981"
                  // defaultValue={`https://weje.com/?adsId=${state.uid}`}
                  defaultValue={`https://weje.com`} />
                <InputGroup.Append>
                  <CopyToClipboard
                    text={`https://weje.com`}
                    onCopy={handleCopy} >
                    <Button type="button">
                      <img src={copyicon} alt="copy icon" />
                    </Button>
                  </CopyToClipboard>
                </InputGroup.Append>
              </InputGroup>
              <Overlay show={state.showCopyTooltip} placement="top">
                {(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    <Translate>Copied</Translate>
                  </Tooltip>
                )}
              </Overlay>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
            show={state.PrivacyPolicyShow}
            onHide={handlePrivacyPolicyClose}
            centered
            className="policy-popup" >
            <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
                <PrivacyPolicy />
          </Modal.Body>
        </Modal>

        <Modal
          show={state.TermsOfUseShow}
          onHide={handleTermsOfUseClose}
          centered
          className="policy-popup">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <TermsOfUse />
          </Modal.Body>
        </Modal>

        <Modal
          show={state.SignOutShow}
          onHide={handleSignOutClose}
          centered
          className="policy-popup sign-out-popup" >
          <Modal.Header closeButton>
            <Modal.Title><Translate>Sign out?</Translate></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="signout-content">
                <div className="signout-loading">
                  <Lottie options={loadingLottie} height={200} width={200} />
                </div>
              <Button
                className="btn-gold sign-out-btn"
                type="submit"
                disabled={state.isSignoutClicked}
                onClick={doSignout} >
                  <Translate>Yes</Translate>
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Layout>
  );
};

export default Settings;