import React, { useState, useEffect } from 'react';
import { Spinner } from "react-bootstrap";
import axios from 'axios';
import UrlConfig from '../../../utils/ApiConfig';
import { Translate } from "react-auto-translate";

const MaticPriceChart = () => {
  const [priceData, setPriceData] = useState([]);
  const [marketCapUsd, setMarketCapUsd] = useState(0);
  const [name, setName] = useState('Polygon');
  const [priceUsd, setPriceUsd] = useState(0);
  const [supply, setSupply] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKeys = [
          'adf9a348-d2bb-4fcc-8f73-65e88c341b74', 
          '9ea64b98-7bc9-40cb-9664-2e0b9677d310'
        ];
        const randomApiKey = apiKeys[Math.floor(Math.random() * apiKeys.length)];
        const response = await axios.get(UrlConfig.polygonDataUrl, {
          headers: {
            'Authorization': `Bearer ${randomApiKey}`
          }
        });
        const data = response.data.data;

        if (data) {
          // Save the data in state
          const marketCap = data.marketCapUsd ? parseFloat(data.marketCapUsd).toFixed(2) : 0;
          const price = data.priceUsd ? parseFloat(data.priceUsd).toFixed(2) : 0;
          const supplyValue = data.supply ? parseFloat(data.supply).toFixed(2) : 0;
          const priceDataValue = data.market_data?.price_usd || [];

          setMarketCapUsd(marketCap);
          setPriceUsd(price);
          setSupply(supplyValue);
          setPriceData(priceDataValue);

          // Save the values in local storage
          localStorage.setItem('marketCapUsd', marketCap);
          localStorage.setItem('priceUsd', price);
          localStorage.setItem('supply', supplyValue);
          localStorage.setItem('priceData', JSON.stringify(priceDataValue));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    const loadFromLocalStorage = () => {
      const storedMarketCapUsd = localStorage.getItem('marketCapUsd');
      const storedPriceUsd = localStorage.getItem('priceUsd');
      const storedSupply = localStorage.getItem('supply');
      const storedPriceData = localStorage.getItem('priceData');

      if (storedMarketCapUsd && storedPriceUsd && storedSupply && storedPriceData) {
        setMarketCapUsd(parseFloat(storedMarketCapUsd));
        setPriceUsd(parseFloat(storedPriceUsd));
        setSupply(parseFloat(storedSupply));
        setPriceData(JSON.parse(storedPriceData));
      }
    };

    const checkAndFetchData = async () => {
      const lastApiCall = localStorage.getItem('lastApiCallMaticPriceChart');
      const now = new Date();
    
      if (lastApiCall) {
        const lastApiCallDate = new Date(lastApiCall);
        
        // Calculate the time difference in milliseconds
        const timeDifference = now - lastApiCallDate;
    
        // Convert milliseconds to hours
        const hoursPassed = timeDifference / (1000 * 60 * 60); // Convert to hours
    
        if (hoursPassed < 12) {  // If less than 12 hours have passed
          console.log('API call skipped, already made within the last 12 hours');
          loadFromLocalStorage();
          setLoading(false);
          return;
        }
      }
    
      await fetchData();
      localStorage.setItem('lastApiCallMaticPriceChart', now.toISOString());
    };        
    

    checkAndFetchData();
  }, []);  

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className='wallet-data'>
      <small style={{ fontFamily: 'system-ui', fontSize: "1em" }}>
      <Translate>Network:</Translate> &nbsp;<span style={{ color: '#8461dc' }}>{name}</span>
      </small><br />
      <small style={{ fontFamily: 'system-ui', fontSize: "1em" }}>
      <Translate>Market Usd:</Translate> &nbsp;<span style={{ color: '#8461dc' }}>{marketCapUsd !== 0 ? marketCapUsd : <Spinner animation="border" size="sm" />}</span>
      </small><br />
      <small style={{ fontFamily: 'system-ui', fontSize: "1em" }}>
      <Translate>Price Usd:</Translate> &nbsp;<span style={{ color: '#8461dc' }}>{priceUsd !== 0 ? priceUsd : <Spinner animation="border" size="sm" />}</span>
      </small><br />
      <small style={{ fontFamily: 'system-ui', fontSize: "1em" }}>
      <Translate>Supply:</Translate> &nbsp;<span style={{ color: '#8461dc' }}>{supply !== 0 ? supply : <Spinner animation="border" size="sm" />}</span>
      </small><br />
    </div>
  );
};

export default MaticPriceChart;