import {useContext, useEffect, useState} from 'react';
import { Button } from "react-bootstrap";
import { isMobile, isBrowser } from "react-device-detect";
import { useWeb3React } from "@web3-react/core";
import { metaMask } from './metamask';
import NewAccount from '../signUp/newaccount';
import connecting from "../../assets/animation/connecting";
import metalogo from "../../assets/icons/metamask-icon.png";
import trustlogo from "../../assets/icons/trustwallet-icon.png";
import coinbaselogo from "../../assets/icons/coinsbase.png";
import Lottie from "react-lottie";
import toast from "react-hot-toast";
import loading from "../../assets/animation/main_loader.json";
import "./crypto.css";
import axios from 'axios';
import firebase from '../../firebase';
import { MainContext } from '../../context';
import UrlConfig from "../../utils/ApiConfig";
import { Translate } from "react-auto-translate";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };

  const connectOptions = {
    loop: true,
    autoplay: true,
    animationData: connecting,
  };

const ConnectButton = (props) => {
const [state] = useState({
    xtkn: props.xtkn,
    deviceId: props.deviceId,
    ip: props.ip,
    isMobile: props.isMobile || isMobile,
    countryCode: props.countryCode,
    fmctoken: props.fmctoken || localStorage.getItem("fmcToken"),
});
const [error, setError] = useState('');
const [walletName, setwalletName] = useState(localStorage.getItem('provider') || 'null');
const [showaccount, setshowaccount] = useState(false);
const { account, provider } = useWeb3React();
const [connected, setConnected] = useState(false);
const [idtoken, setidtoken] = useState('');
const { firebaseUser, setBaseStates, setFirebaseUser, setToken } = useContext(MainContext);

// Call this function only when login
    const handleLogin = async() => {
        toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
            id: "walletConnectAPI-loading",
            style: {
                borderRadius: "15px",
            },
        });
    const res = await axios.get(UrlConfig.walletConnectAPI, {
            params: {
            isMobile: state.isMobile.toString(),
            countryCode: props.countryCode,
            widProvider: walletName,
            },
            headers: {
            device: props.deviceId || localStorage.getItem("deviceId"),
            ip: props.ip,
            walletid: account,
            fmcToken: props.fmcToken || localStorage.getItem("fmcToken"),
            idtoken: localStorage.getItem("idtoken"),
            },
            withCredentials: true,
            credentials: "include",
    });
        if (res.data.msg === "user logged in with same device" && res.data.goProfile) {
            //user goes to profile page;
            setBaseStates({
                ...res.data.getDoc.doc,
              uid: "",
              doc: res.data.getDoc.doc,
              userDoc: res.data.getDoc.userDoc,
              inGame: res.data.getDoc.inGame,
              inQue: res.data.getDoc.inQue,
              currency: res.data.getDoc.currency,
              datetimeNow: res.data.getDoc.datetimeNow
            });

        } else if (res.data.error === "nick required when wid NOT exists") {
            setshowaccount(true);
            toast.success(<span><Translate>Create your account</Translate></span>, {
                duration: 4000,
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
        } else if ((res.data.error === "Auth-Token and wallet id mismatch")) {
            toast.error(<span><Translate>Clear your web browser's cache and cookies, then try again</Translate></span>, {
                duration: 4000,
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
        } else if (res.data.error !== "no error") {
                toast.error(<Translate>{res.data.error}</Translate>, {
                    duration: 7000,
                    style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                    },
                })
        } else {
                // SUCCESS, user goes to profile page;
                setBaseStates({
                    ...res.data.getDoc.doc,
                    uid: "",
                    doc: res.data.getDoc.doc,
                    userDoc: res.data.getDoc.userDoc,
                    inGame: res.data.getDoc.inGame,
                    inQue: res.data.getDoc.inQue,
                    currency: res.data.getDoc.currency,
                    datetimeNow: res.data.getDoc.datetimeNow
                });
                const currentUser = await firebase.auth().signInWithCustomToken(res.data.idtoken || localStorage.getItem("idtoken"))
                const idToken = await currentUser.user.getIdToken(true);
                setidtoken(idToken);
                setToken(idToken);
                setFirebaseUser(currentUser.user);
                localStorage.setItem("idtoken", idToken);
                localStorage.setItem("deviceId", props.deviceId);
                localStorage.setItem("fmcToken", props.fmcToken);
                localStorage.setItem("provider", walletName);
                toast.success(
                    <span>
                      <Translate>Welcome to </Translate>
                      WEJE!
                    </span>,
                    {
                      duration: 4000,
                      style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                      },
                    }
                  );
                if(res.data.msg === "refresh idtoken, set local storage, and call CookieSetter" 
                || res.data.msg === "login success" 
                || res.data.msg === "call the cookie-setter-api") {
                await axios.get(`${UrlConfig.cookieSetterAPI}`, {
                    headers: {
                        idtoken: idToken,
                        wid: account,
                    },
                    params: {
                        isLogout: "no"
                    },
                    withCredentials: true,
                    credentials: "include"
                })
                }
               if (window.location.pathname === "/") {
                    window.location.href="/profile"
                }
            }
        }

        function checkConnection() {
            if (window.ethereum) {
                window.ethereum.request({ method: 'eth_accounts' }).then(handleAccountsChanged).catch(console.error);
                function handleAccountsChanged(accounts) {
                    if (accounts.length === 0) {
                        localStorage.setItem('isWalletConnected', false);
                      } 
                    }
                    const isCoinbase = window.ethereum.isCoinbaseWallet;
                    const isMetaMask = window.ethereum.isMetaMask;
                    const isTrust = window.ethereum.isTrust;
                    console.log(isCoinbase + "isCoinbase");
                    console.log(isMetaMask + "isMetaMask");
                    console.log(isTrust + "isTrust");
                    if (isCoinbase) {
                        setwalletName('Coinbase');
                    }  if (isMetaMask) {
                        setwalletName('MetaMask');
                    }  if (isTrust && isMetaMask) {
                        setwalletName('TrustWallet');
                    }
                } else {
                    localStorage.setItem('isWalletConnected', false);
                    toast.error(<span><Translate>No Ethereum provider is available</Translate></span>, {
                        duration: 4000,
                        style: {
                            fontSize: "14px",
                            borderRadius: "5px",
                        },
                    });
                }
            }

            useEffect(() => {
                checkConnection();
                const getAccountData = async() => {
                    if (account) {
                        localStorage.setItem('isWalletConnected', true);
                        document.cookie = `walletid= ${account}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
                        localStorage.setItem('walletid', account);
                    }
                }
                if (!account) {
                    handleConnectWallet();
                } else if (account) {
                    getAccountData()
                if (!firebaseUser && account) {
                    handleLogin();
                    }
                }
            }, [account, provider, firebaseUser]);  

            async function handleConnectWallet(walletType) {
                    // Get the current origin from the URL (e.g., https://weje.com or https://beta.weje.com)
                    const origin = window.location.origin;
                    if (!window.ethereum && isMobile) {
                        if (walletType === "MetaMask") {
                            // Dynamically insert the origin in the MetaMask URL
                            return window.open(`https://metamask.app.link/dapp/${origin.replace(/^https?:\/\//, '')}`, '_blank');
                        } else if (walletType === "Coinbase") {
                            // Dynamically insert the origin in the Coinbase URL
                            return window.open(`https://go.cb-w.com/dapp?cb_url=${origin}`, '_blank');
                        }
                    }

            if (window.ethereum) {
                // Check if the user is on mobile and using a specific wallet provider
                const isCoinbase = window.ethereum.isCoinbaseWallet;
                const isMetaMask = window.ethereum.isMetaMask;
                const isTrustWallet = window.ethereum.isTrust;
                // Set connection status and handle wallet connection
                setConnected(true);
                try {
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    localStorage.setItem('isWalletConnected', true);
                    // Activate MetaMask with Polygon Mainnet configuration
                    metaMask.activate({
                        chainId: 137, // Polygon Mainnet
                        chainName: "Polygon Mainnet",
                        nativeCurrency: {
                            name: 'Matic',
                            symbol: 'MATIC',
                            decimals: 18,
                        },
                        rpcUrls: ["https://polygon-rpc.com/"],
                        blockExplorerUrls: ["https://polygonscan.com/"]
                    });
                    // Determine wallet type and set the wallet name
                    if (isCoinbase) {
                        setwalletName('Coinbase');
                    } else if (isMetaMask) {
                        setwalletName('MetaMask');
                    } else if (isTrustWallet && isMetaMask) {
                        setwalletName('TrustWallet');
                    }
                    // Redirect to profile page if the user is authenticated
                    if (firebaseUser && accounts[0] && window.location.pathname === "/") {
                        window.location.href = "/profile";
                    }
                } catch (error) {
                    // Handle errors related to wallet connection
                    if (error.code === 4001) {
                        console.log("User denied the request");
                    } else if (error.code === -32002) {
                        toast.loading(<span><Translate>Wallet permissions pending..</Translate></span>, {
                            duration: 4000,
                            style: {
                                maxWidth: 800,
                                fontSize: "14px",
                                borderRadius: "15px",
                            },
                        });
                    } else {
                        console.error("Error connecting:", error);
                    }
                }
            } else {
                // Handle case where no Ethereum provider is available
                setError("No Ethereum provider is available");
                localStorage.setItem('isWalletConnected', false);
                toast.error(<span><Translate>No Ethereum provider is available</Translate></span>, {
                    duration: 4000,
                    style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                    },
                });
            }
            // Reload the page if there was an error
            if (error) {
                setTimeout(() => {
                    window.location.reload(false);
                }, 4000);
            }
        }

        useEffect(() => {
            localStorage.setItem("provider", walletName);
        }, [walletName])


        return (
                    <>
                    {error && !isMobile ? (
                    <div className="links-div">
                        <p className="p-link"><Translate>Install for your browser</Translate> <a className="metamask-link" target="_blank" href="https://metamask.io/download/" rel="noreferrer">&nbsp;Metamask</a></p>
                        <p className="p-link"><Translate>Install for your browser</Translate> <a className="coinbase-link" target="_blank" href="https://www.coinbase.com/wallet/downloads" rel="noreferrer">&nbsp;Coinbase</a></p>
                        <p className="p-link"><Translate>Install for your browser</Translate> <a className="trust-link" target="_blank" href="https://trustwallet.com/download/" rel="noreferrer">&nbsp;Trust Wallet</a></p>  
                    </div>            
                    ) : null}
                    {connected && !showaccount? (
                        <div className="connectOptions">
                            <Lottie options={connectOptions} height={250} width={250} />
                        </div>
                    ) : null}
                    {account && showaccount ? (
                        <div>
                            <NewAccount
                            idtoken={idtoken}
                            walletid={account}
                            deviceId={state.deviceId}
                            xtkn={state.xtkn}
                            ip={state.ip}
                            isMobile={state.isMobile}
                            countryCode={state.countryCode}
                            fmctoken={state.fmctoken}
                            walletName={walletName}
                            />
                        </div>
                    ) : 
                        <div>
                    {isBrowser && !isMobile ? (
                                <Button
                                    id={walletName}
                                    className="google-login-btn"
                                    onClick={handleConnectWallet} >
                                        <Translate>Connect Wallet</Translate>
                                    {walletName === "MetaMask" ? (
                                        <img src={metalogo} className="crypto-icon" alt="MetaMask" id="metamask-icon" />
                                    ) : null}
                                    {walletName === "TrustWallet" ? (
                                        <img src={trustlogo} className="crypto-icon" alt="TrustWallet" id="trust-icon" />
                                    ) : null}
                                    {walletName === "Coinbase" ? (
                                        <img src={coinbaselogo} className="crypto-icon" alt="TrustWallet" id="coinbase-icon" />
                                    ) : null}
                                </Button>
                            ) : 
                            <div className='mobile-btns'>
                                <button 
                                className="MetaMask" 
                                onClick={() => handleConnectWallet('MetaMask')}
                                ><img src={metalogo} className="crypto-icon" alt="MetaMask" id="metamask-icon" /> MetaMask
                                </button>
                                <button 
                                className="Coinbase" 
                                onClick={() => handleConnectWallet('Coinbase')}
                                ><img src={coinbaselogo} className="crypto-icon" alt="Coinbase" id="coinbase-icon" /> Coinbase
                                </button>
                            </div>
                            }
                        </div>
                    }
                </>
          );
        
    }

export default ConnectButton;