import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Modal, Button, Spinner } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import CryptoNOT from "../cryptoNOT/index";
import LogContainer from "./../sports-bet-filter/LogContainer";
import walletIcon from '../../assets/images/header/coin.png';
import Lottie from "react-lottie";
import { ethers } from "ethers";
// import loading from "../../assets/animation/loading.json";
import confirm from "../../assets/animation/confirm.json";
import blockc from "../../assets/animation/block.json";
import { getUserStats } from "../../dbFetchFunctions";
import axios from 'axios';
import toast from 'react-hot-toast';
import Layout from '../layout/layout';
import './bet.css';
import UrlConfig from "../../utils/ApiConfig";
import SportsAbi from "../../ABI/SportsAbi.json";
import { Translate } from "react-auto-translate";
import { MainContext } from "../../context";
import {v4} from "uuid";
import { generateUniqueId } from "../../utils";

const confirmLottie = {
    loop: true,
    autoplay: true,
    animationData: confirm,
  };
  
  const blockLottie = {
    loop: true,
    autoplay: true,
    animationData: blockc,
  };

const SportBetForm = (props) => {
    const Uid = props.uid;
    const [friends, setfriends] = useState(0);
    const [contract, setContract] = useState();
    const { firebaseUser } = useContext(MainContext);

    const [sportsData] = useState([
        ...props.ChampionsLeagueData,
        ...props.EuropaLeagueData,
        ...props.NBAData,
        ...props.EuroLeagueData,
        ...props.RussiaVTBLeagueData,
        ...props.SpainACBLeagueData,
        ...props.TurkeyTBSLLeagueData,
        ...props.BrazilNBBLeagueData,
        ...props.ChinaCBALeagueData,
        ...props.NHLData,
        ...props.AHLData,
        ...props.KHLData,
        ...props.AustralianOpenData,
        ...props.USOpenData,
        ...props.EnglandLeagueData,
        ...props.GermanyLeagueData,
        ...props.SpainLeagueData,
        ...props.BrazilLeagueData,
        ...props.ArgentinaLeagueData,
        ...props.IndiaLeagueData,
   
    ]);

    const [allGamesCount, setAllGamesCount] = useState(0);
    const [firstGameStart, setFirstGameStart] = useState('');
    const [walletConnected, setWalletConnected] = useState(true);
    const [balance, setBalance] = useState('');
    const [message, setMessage] = useState('');
    const { account, provider } = useWeb3React();
    const [confirmationText, setconfirmationText] = useState('');
    const [ConfirmationShow, setConfirmationShow] = useState(false);
    const [loading, setloading] = useState(false);

    const fetchUserStats = async (Uid) => {
        try {
            const fetchedUserStats = await getUserStats(Uid);
            setfriends(fetchedUserStats.social.friends.toLocaleString() || 0);
        } catch (error) {
            console.error('Error fetching user stats:', error);
        }
    };

    useEffect(() => {
        fetchUserStats(Uid);
    }, [Uid]);

    useEffect(() => {
        const getBalance = async () => {
            if (account) {
                try {
                    const value = await provider?.getBalance(account);
                    if (value) {
                        const formattedBalance = Number(ethers.utils.formatEther(value)).toFixed(5);
                        setBalance(formattedBalance);
                        setWalletConnected(true);
                        setMessage("");
                    }
                } catch (error) {
                    console.error('Error fetching balance:', error);
                    setWalletConnected(false);
                    setMessage("Error fetching balance");
                }
            } else {
                setWalletConnected(false);
                setMessage("Crypto wallet is not connected");
            }
        };
        getBalance();
        if(account && provider){
            setContract(new ethers.Contract(process.env.REACT_APP_SPORTS_CONTRACT_ADDRESS, SportsAbi, provider.getSigner()));
        }
    }, [account, provider]);

    useEffect(() => {
        setAllGamesCount(sportsData.length);
        let games = [...sportsData];
        games.sort((a, b) => new Date(a.begin) - new Date(b.begin));
        setFirstGameStart(games[0]?.begin || '');
    }, [sportsData]);


    const confirmationClose = () => {
        setConfirmationShow(!ConfirmationShow);
    };

    const validate = (formBetAmount) => {
        // Validation for max games limit
        if (allGamesCount > 24) {
            toast.error(<span><Translate>You can select up to 25 games</Translate></span>, {
                duration: 4000,
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
            return false;
        }
        // Polygon MATIC ballance
        if (Number(balance) < formBetAmount) {
            const deficit = (formBetAmount - Number(balance)).toFixed(2);
            toast.error(<span><Translate>You are short <b>{deficit} POL</b> to place a bet</Translate></span>, {
                  duration: 4000,
                  style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                  },
                }
              );              
            setMessage(`You are short <b>{deficit} POL</b> to create a bet`);
            return false;
        }
        if(!firebaseUser.uid || !firebaseUser.displayName){
            toast.error(<span><Translate>User not found error</Translate></span>, {
                  duration: 4000,
                  style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                  },
                }
              );
            setMessage(`User not found`);
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
       
        const formBetAmount = e.target.elements.formBetAmount.value;
        const isValid = validate(formBetAmount);
        if (!isValid) {
            return;
        }
        setloading(true);
        setconfirmationText('Please Confirm Transaction');
        try {
            const response = await axios.post(UrlConfig.createTicketValidation, {
                matches: sportsData.map(game => ({ gameId: game.id }))
            });
            console.log("validate response", response.data);
            if (response.data.success) {
                let selections = [];
                let startDate, endDate = new Date();
                let matches = sportsData.map(game => {
                    if(!startDate || new Date(game.begin) < startDate){
                        startDate = new Date(game.begin);
                        console.log("startDate", startDate);
                    }
                    if(new Date(game.end) > endDate){
                        endDate = new Date(game.end);
                        console.log("endDate", endDate);
                    }
                    selections.push([
                        game.id, 
                        game.choice === "home" ? game.homeId : 
                        game.choice === "away" ? game.awayId : 
                        0
                    ]);
                    return [
                        game.id,
                        game.isDrawable,
                        game.homeId,
                        game.awayId,
                        game.home,
                        game.away,
                        game.homeLogo,
                        game.awayLogo
                    ];
                });
               
                let user = [Uid, firebaseUser.displayName, firebaseUser.photoURL, account];
                let betId = generateUniqueId();
                const valueToSend = ethers.utils.parseUnits(formBetAmount.toString(), 18);
                let betInfo = [valueToSend, betId, Date.parse(startDate), Date.parse(endDate)];
               console.log("betInfo", betInfo, user, matches, selections);
                const receipt = await createTicket(betInfo, valueToSend, matches, user, selections);
                setloading(false);
                if (receipt) {
                    const shortenedBetId = betId.toString().slice(-5);
                    toast.success(
                    <span>
                    <Translate>Ticket </Translate> 
                    {shortenedBetId} 
                    <Translate> has been received</Translate>
                    </span>, {
                        duration: 3000,
                        id: "toast-success",
                        style: {
                          maxWidth: 800,
                          fontSize: "14px",
                          borderRadius: "15px",
                        },
                    });
                    setTimeout(() => {
                        window.location.href = `/sports-tickets?ticket=${betId}`;
                      }, 3000);
                }
            }
        } catch (error) {
            console.error("Error during form submission", error);
            // Handle error, show toast or alert
        } finally {
            setloading(false); // Ensure loading state is reset
        }
    };
    

    const createTicket = async (betInfo, valueToSend, matches, user, selections) => {
        let tx;
        console.log("calling create tickegt")
        try {
          if (contract) {
            const { data: { result: { FastGasPrice } = {}} = {}} = await axios.get(`${UrlConfig.gasTrackerUrl}${process.env.REACT_APP_API_TOKEN}`);
            // estimeate gasPrice using estimateGas functions
            const estimatedGasLimit = await contract.estimateGas.openBet(
                betInfo, matches, user, selections, {
                value: valueToSend,
                gasPrice: ethers.utils.parseUnits(FastGasPrice.toString() || '35', 'gwei'),
                nonce: await provider.getTransactionCount(account),
            });
           console.log("limit",ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)), Math.floor(Number(estimatedGasLimit) * 1.2));
            tx = await contract.openBet(
                betInfo, matches, user, selections,{
                value: valueToSend,
                gasPrice: ethers.utils.parseUnits(FastGasPrice.toString() || '35', 'gwei'),
                nonce: await provider.getTransactionCount(account),
                gasLimit: ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)),
            });
            setconfirmationText('Waiting For Block Confirmation');
            const receipt = await tx.wait();
           return receipt;
          }
        } catch (e) {
            console.log("error in createTicket", e);
          if (e.message.includes("execution reverted")) {
            const revertReason = await contract.provider.getTransactionReceipt(tx.hash)
              .then((receipt) => receipt ? receipt.reason : "No revert reason");
                toast.error(<span><Translate>Revert Reason: {revertReason} </Translate></span>, {
                duration: 4000,
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            });
            setConfirmationShow(true);
            setloading(false);
            setconfirmationText('Waiting For Block Confirmation');
            return false;
          } else {
            if (e.message.includes("insufficient funds")) {
              toast.error(<span><Translate>Not enough balance to create a game</Translate></span>, {
                duration: 4000,
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                  },
                }); 
              }
            setConfirmationShow(true);
            setloading(false);
            return false;
          }
        }
      };

    return (
        <>
            {walletConnected ? (
                <Layout>
                    <div className='bet-las-vegas'>
                        <div className='container'>
                            <div className='row'>
                            <div className="log-container">
                                <LogContainer
                                    ChampionsLeagueData={props.ChampionsLeagueData}
                                    IndiaLeagueData={props.IndiaLeagueData}
                                    EuropaLeagueData={props.EuropaLeagueData}
                                    EnglandLeagueData={props.EnglandLeagueData}
                                    GermanyLeagueData={props.GermanyLeagueData}
                                    SpainLeagueData={props.SpainLeagueData}
                                    BrazilLeagueData={props.BrazilLeagueData}
                                    ArgentinaLeagueData={props.ArgentinaLeagueData}
                                    NBAData={props.NBAData}
                                    EuroLeagueData={props.EuroLeagueData}
                                    RussiaVTBLeagueData={props.RussiaVTBLeagueData}
                                    SpainACBLeagueData={props.SpainACBLeagueData}
                                    TurkeyTBSLLeagueData={props.TurkeyTBSLLeagueData}
                                    BrazilNBBLeagueData={props.BrazilNBBLeagueData}
                                    ChinaCBALeagueData={props.ChinaCBALeagueData}
                                    NHLData={props.NHLData}
                                    AHLData={props.AHLData}
                                    KHLData={props.KHLData}
                                    AustralianOpenData={props.AustralianOpenData}
                                    USOpenData={props.USOpenData}
                                />
                            </div>
                                <div className='col-md-6 m-auto'>
                                    <div className='betting-form'>
                                        <Form onSubmit={handleSubmit} className="handleSubmit-a">
                                                    <div className='winner-selected'>
                                                        <h3><Translate>Winner</Translate></h3>
                                                    </div>
                                            <div className='game-select-container'>
                                                <div className='game-selected'>
                                                    <Form.Group controlId="formBetAmount">
                                                        <Form.Label>
                                                            <p className="matic-p">
                                                                 POL
                                                            </p>
                                                        </Form.Label>
                                                        <Form.Control 
                                                            type="number" 
                                                            defaultValue={5} 
                                                            min="1" 
                                                            step="1" 
                                                            name="betAmount"
                                                            className="bet-input" 
                                                            pattern="[0-9]*"
                                                        />
                                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                    </Form.Group>
                                                </div>
                                                <div className='game-selected total-games'>
                                                    <span>{allGamesCount}</span>
                                                    <p><Translate>Total Games</Translate></p>
                                                </div>
                                            </div>
                                            <div className="row col-md-12">
                                                <div className="col-sm-6">
                                                    <Form.Group
                                                        controlId="formBasicFirst"
                                                        className="coin-input">
                                                        <Form.Label>
                                                            Polygon <small>POL<span style={{ fontSize: "10px" }}>(MATIC)</span></small>
                                                        </Form.Label>
                                                        <div className="tooltipHover">
                                                            <img src={walletIcon} alt="info" className="winner-matic" />
                                                            <small className="tooltiptext balance-winner">
                                                                Polygon <Translate>Balance</Translate>
                                                            </small>
                                                        </div>
                                                        <div className="your-wallet">
                                                            <img src={walletIcon} alt="info" className="PolygonCoins" />
                                                            <p className="your-wallet-p">
                                                                {balance}
                                                            </p>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="bet-close"><Translate>Betting starting time:</Translate> <span className="close-b">{new Date(firstGameStart).toLocaleString()}</span></p>
                                            </div>
                                            <div>
                                                <Button type='submit' className='place-bet-btn l-btn'>
                                                    <Translate>Place bet</Translate>
                                                </Button>
                                            </div>
                                            <div className='login-button'>
                                                <Button
                                                    className='cancel-btn'
                                                    onClick={props.prevStep}>
                                                    <Translate>Cancel</Translate>
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            {loading ? (
                                <>
                                    <Modal
                                        show={true}
                                        onHide={() => confirmationClose()}
                                        centered
                                        className="sports-bet-pending friends-popup pending-popup">
                                        <Modal.Header >
                                        <Modal.Title>
                                        <Spinner animation="border" />
                                        <small className='title-pending'>
                                        {confirmationText === "Waiting For Block Confirmation" ? (
                                            <span style={{ color: '#8461dc' }}>
                                            <Translate>Waiting For Block Confirmation</Translate>
                                            </span>
                                        ) : (
                                            <Translate>{confirmationText}</Translate>
                                        )}
                                        </small>
                                        </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="feed-body">
                                            { confirmationText === "Please Confirm Transaction" ? (
                                            <Lottie options={confirmLottie} width={250} height={250} />
                                            ) : <Lottie options={blockLottie} width={300} height={250} /> }
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </>
                            ) : null}
                        </div>
                    </div>
                </Layout>
            ) : (
                <CryptoNOT />
            )}
        </>
    );
};

export default SportBetForm;
