import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom"
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
ReactDOM.render(
  <React.StrictMode>
    <Router>
    <div id="google_translate_element"></div>
		<App />
	</Router>
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.register();
