import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-auto-translate";
import "./popup.css";

const RegisterWinning = () => {
  return (
    <div className={`winning-bonus`}>
      <div className="winning-bonus-grid">
                <img
                    src=""
                    alt="treasure"
                    className="treasure"
                />
        <h2><Translate>Congratulations</Translate></h2>
        <p><Translate>Your account has been created</Translate></p>
        <Link to=""><Translate>Play</Translate></Link>
      </div>
    </div>
  );
};

export default RegisterWinning;
