import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-auto-translate";
import winningAmount from "../../assets/images/popups/handshake2.gif";
import "./popup.css";

const FriendPopup = ({ userName }) => {
  return (
    <div className={`friend-bonus`}>
      <div className="friend-bonus-grid">
        <img
          src={winningAmount}
          alt="winningamount"
          className="wow animate__animated animate__shakeX "
        />
        <h2><Translate>Congratulations!</Translate></h2>
        <p>
          <Translate>You and</Translate> <span>{userName}</span> <Translate>are friends now, invite</Translate> {userName} <Translate>to play</Translate>
        </p>
        <Link to=""><Translate>Invite Now</Translate></Link>
      </div>
    </div>
  );
};
export default FriendPopup;
