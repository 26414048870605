import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import { Translate } from "react-auto-translate";
import loading from "../../assets/animation/loading.json";
import "./report.css";

const loadingLottie = {
  loop: true,
  autoplay: true,
  animationData: loading,
};

const ReportPostPopup = ({ feedId, idToken, ownerId }) => {
  const [step, setStep] = useState("");
  const [loader, setLoader] = useState(false);
  const [reports, setReports] = useState([]);
  const handleStep = (value, final) => {
    if (reports.includes(value)) {
      setReports(reports.filter((el) => el !== value));
    } else {
      let rep = [...reports];
      rep.push(value);
      setReports(rep);
    }
    setStep(final || value);
  };

  const handleStepBack = () => {
    let rep = [...reports];
    rep.pop();
    if (rep.length) setStep(rep[rep.length - 1]);
    else setStep("");
    setReports(rep);
  };

  const getStepReport = () => {
    let content = "";
    switch (step) {
      case "Sexual content":
        content = (
          <div className="report-popup-content">
            <h4>
              <Translate>What kind of Sexual content?</Translate>
            </h4>
            <p>
              <Translate>
                If you think this post is offensive or with potential violating content,
                please let us know.
              </Translate>
            </p>
            <ul>
              <li onClick={() => handleStep("Sexually Suggestive", "final")}>
                <Translate>Sexually Suggestive</Translate>
              </li>
              <li onClick={() => handleStep("Sexual Activity", "final")}>
                <Translate>Sexual Activity</Translate>
              </li>
              <li onClick={() => handleStep("Sexual Exploitation", "final")}>
                <Translate>Sexual Exploitation</Translate>
              </li>
              <li onClick={() => handleStep("Sexual Services", "final")}>
                <Translate>Sexual Services</Translate>
              </li>
              <li onClick={() => handleStep("Involves a Child", "final")}>
                <Translate>Involves a Child</Translate>
              </li>
              <li onClick={() => handleStep("Something else", "final")}>
                <Translate>Something else</Translate>
              </li>
            </ul>
          </div>
        );
        break;
      case "Violence":
        content = (
          <div className="report-popup-content">
            <h4>
              <Translate>What kind of violence?</Translate>
            </h4>
            <p>
              <Translate>
                If someone is in immediate danger, get help before reporting to Facebook.
                Don't wait.
              </Translate>
            </p>
            <ul>
              <li onClick={() => handleStep("Violent Threat", "final")}>
                <Translate>Violent Threat</Translate>
              </li>
              <li onClick={() => handleStep("Animal Abuse", "final")}>
                <Translate>Animal Abuse</Translate>
              </li>
              <li onClick={() => handleStep("Sexual Violence", "final")}>
                <Translate>Sexual Violence</Translate>
              </li>
              <li onClick={() => handleStep("Something Else", "final")}>
                <Translate>Something Else</Translate>
              </li>
            </ul>
          </div>
        );
        break;
      case "False Information":
        content = (
          <div className="report-popup-content">
            <h4>
              <Translate>What kind of False Information?</Translate>
            </h4>
            <p>
              <Translate>
                If someone is in immediate danger, get help before reporting to Facebook.
                Don't wait.
              </Translate>
            </p>
            <ul>
              <li onClick={() => handleStep("Health", "final")}>
                <Translate>Health</Translate>
              </li>
              <li onClick={() => handleStep("Politics", "final")}>
                <Translate>Politics</Translate>
              </li>
              <li onClick={() => handleStep("Social Issue", "final")}>
                <Translate>Social Issue</Translate>
              </li>
              <li onClick={() => handleStep("Something Else", "final")}>
                <Translate>Something Else</Translate>
              </li>
            </ul>
          </div>
        );
        break;
      case "Spam":
        content = (
            <div className="report-popup-content">
                <h4>
                  <Translate>Spam</Translate>
                </h4>
                <p>
                  <Translate>
                    We don't allow things such as:
                  </Translate>
                  <ul>
                    <li>
                      <Translate>Buying, selling or giving away accounts, roles or permissions</Translate>
                    </li>
                    <li>
                      <Translate>Encouraging people to engage with content under false pretences</Translate>
                    </li>
                  </ul>
                </p>
                <div className="action">
                  <button onClick={handleReport}>
                    {loader ? <Spinner animation="border" /> : <Translate>Submit</Translate>}
                  </button>
                </div>
            </div>
        );
        break;
      case "Unauthorized Sale":
        content = (
          <div className="report-popup-content">
            <h4>
              <Translate>What kind of unauthorised sales?</Translate>
            </h4>
            <p>
              <Translate>
                If someone is in immediate danger, get help before reporting to Facebook.
                Don't wait.
              </Translate>
            </p>
              <ul>
                <li onClick={() => handleStep("Drugs", "final")}>
                  <Translate>Drugs</Translate>
                </li>
                <li onClick={() => handleStep("Weapons", "final")}>
                  <Translate>Weapons</Translate>
                </li>
                <li onClick={() => handleStep("Endangered animals", "final")}>
                  <Translate>Endangered Animals</Translate>
                </li>
                <li onClick={() => handleStep("Other animals", "final")}>
                  <Translate>Other animals</Translate>
                </li>
                <li onClick={() => handleStep("Something Else", "final")}>
                  <Translate>Something Else</Translate>
                </li>
              </ul>
            </div>
        );
        break;
      case "Hate Speech":
        content = (
          <div className="report-popup-content">
              <h4>
                <Translate>What kind of hate speech?</Translate>
              </h4>
              <p>
                <Translate>
                  If someone is in immediate danger, get help before reporting to Facebook.
                  Don't wait.
                </Translate>
              </p>
              <ul>
                <li onClick={() => handleStep("Race or Ethnicity", "final")}>
                  <Translate>Race or Ethnicity</Translate>
                </li>
                <li onClick={() => handleStep("National Origin", "final")}>
                  <Translate>National Origin</Translate>
                </li>
                <li onClick={() => handleStep("Religious Affiliation", "final")}>
                  <Translate>Religious Affiliation</Translate>
                </li>
                <li onClick={() => handleStep("Social Caste", "final")}>
                  <Translate>Social Caste</Translate>
                </li>
                <li onClick={() => handleStep("Sexual Orientation", "final")}>
                  <Translate>Sexual Orientation</Translate>
                </li>
                <li onClick={() => handleStep("Sex or Gender Identity", "final")}>
                  <Translate>Sex or Gender Identity</Translate>
                </li>
                <li onClick={() => handleStep("Disability or Disease", "final")}>
                  <Translate>Disability or Disease</Translate>
                </li>
                <li onClick={() => handleStep("Something Else", "final")}>
                  <Translate>Something Else</Translate>
                </li>
              </ul>
            </div>
        );
        break;
      case "Terrorism":
        content = (
          <div className="report-popup-content">
              <h4>
                <Translate>Terrorism</Translate>
              </h4>
              <p>
                <Translate>
                  We only remove content that goes against our Community Standards.
                </Translate>
              </p>
              <h6>
                <Translate>
                  We remove content about any non-governmental group or person that engages in or supports planned acts of violence for political, religious or ideological reasons.
                </Translate>
              </h6>
              <div className="action">
                <button onClick={handleReport}>
                  {loader ? <Spinner animation="border" /> : <Translate>Submit</Translate>}
                </button>
              </div>
            </div>
        );
        break;
      case "final":
        content = (
          <div className="report-popup-content">
            <h4><Translate>Submit Report</Translate></h4>
            <p><Translate>Selected categories...</Translate></p>
            <h6>
              {reports.map((el) => (
                <span>&nbsp;{el}</span>
              ))}
            </h6>

            <div className="action">
              <button className="btn-gold" onClick={handleReport}>
                {loader ? <Spinner animation="border" /> : <Translate>Submit</Translate>}
              </button>
            </div>
          </div>
        );
        break;
      case "success":
        content = (
          <div className="report-popup-success">
              <h4>
                <Translate>You have reported this person successfully.</Translate>
              </h4>
              <p>
                <Translate>
                  Our Standards explain what we do and don't allow on Facebook. We review and update our Standards regularly, with the help of experts.
                </Translate>
              </p>
          </div>
        );
        break;
      default:
        content = (
          <div className="report-popup-content">
              <h4>
                <Translate>Please select a problem</Translate>
              </h4>
              <p>
                <Translate>
                  If someone is in immediate danger, get help before reporting to Facebook. Don't wait.
                </Translate>
              </p>
              <ul>
                <li onClick={() => handleStep("Sexual content")}>
                  <Translate>Sexual content</Translate>
                </li>
                <li onClick={() => handleStep("Violence")}>
                  <Translate>Violence</Translate>
                </li>
                <li onClick={() => handleStep("False Information")}>
                  <Translate>False Information</Translate>
                </li>
                <li onClick={() => handleStep("Spam")}>
                  <Translate>Spam</Translate>
                </li>
                <li onClick={() => handleStep("Hate Speech")}>
                  <Translate>Hate Speech</Translate>
                </li>
                <li onClick={() => handleStep("Terrorism")}>
                  <Translate>Terrorism</Translate>
                </li>
              </ul>
          </div>
        );
    }
    return content;
  };

  const handleReport = async () => {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      id: "toast-loading",
      duration: 6000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    setLoader(true);
    const res = await axios.get(
      "https://report-t3e66zpola-uc.a.run.app/reportContent",
      {
        headers: {
          idtoken: idToken,
          path: `messanger/${ownerId}/feed/${feedId}`,
        },
        params: {
          frId: ownerId,
          subject: reports.join(" "),
        },
      }
    );
    setLoader(false);
    if (res.data.error === "no error") {
      toast.success(<span><Translate>Thank you for letting us know</Translate></span>, {
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
      setStep("success");
    } else if (res.data.error === "you already reported this path") {
      toast.error(<span><Translate>You've already reported this</Translate></span>, {
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
      setStep("success");
    } else if (res.data.error !== "no error") {
      toast.error(<Translate>{res.data.error}</Translate>, {
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
    }
  };


  return (
    <div className="report-post-popup">
      <div className="report-popup">
        <div className="report-popup-header">
          <h2><Translate>Report</Translate></h2>
          {step !== "" && step !== "success" && (
            <span onClick={handleStepBack}>
              <i className="fa fa-angle-left" />
            </span>
          )}
        </div>

        {getStepReport(step)}
      </div>
    </div>
  );
};
export default ReportPostPopup;
