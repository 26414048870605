import React, { Component } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../../assets/animation/loading.json";
import axios from "axios";
import Loader from "../../loader/loader";
import PeerImage from "../../ImageComponent/imageComponent";
import { MainContext } from "../../../context";
import { Translate } from "react-auto-translate";
import UrlConfig from "../../../utils/ApiConfig";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };

class BlockList extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      blockFriends: [],
      recoveryUId: "",
      recoveryNickName: "",
      recoveryShow: false,
      DeleteUId: "",
      DeleteNickname: "",
      deleteShow: false,
      isTableVisible: false,
      rotating: false,

      //Get personal data from parent
      uid: this.props.uid,
      signedin: this.props.signedin,
      nick: this.props.nick,
      redirect: this.props.redirect,
      pending: true,
      idToken: this.props.idToken,
      observerUNSUB: this.props.observerUNSUB,
    };
  }
  blockTable;

  async componentDidMount() {
    this.handleInitialize();
  }

  async handleInitialize() {
    const context = this.context;
    if (context.blockList.length) {
      this.setState({
        blockFriends: context.blockList,
        isTableVisible: true,
        pending: false,
      });
      this.blockTable = context.blockList;
    } else {
      const UserId = this.state.uid;
      const Uidtoken = this.state.idToken;
      if (UserId) {
        await axios
          .get(UrlConfig.showFriends, {
            params: {
              uid: UserId,
              mode: "profilePage",
              viewMore: "no",
            },
          })
          .then((response) => {
            if (response.data) {
              context.setBlockList(response.data.blockTable);
              this.blockTable = response.data.blockTable;
              this.setState({
                pending: false,
                blockFriends: response.data.blockTable,
                isTableVisible: true,
              });
            }
          })
          .catch((error) => {
            console.log("Error req", error);
          });
      }
    }
  }

  handleRecoveryClose() {
    this.setState({
      recoveryShow: !this.state.recoveryShow,
    });
  }

  handleDeleteClose() {
    this.setState({
      deleteShow: !this.state.deleteShow,
    });
  }

  handleRecovery(event) {
    var res = String(event);
    const param = res.split(",");
    const Uid = param[0];
    const nickname = param[1];

    // let Uid = event;
    this.setState({
      recoveryNickName: nickname,
      recoveryUId: Uid,
      recoveryShow: true,
    });
  }

  UserRecoveryAction(event) {
    event.preventDefault();
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
        id: "toast-loading",
        duration: 6000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
    });
    this.setState({
        rotating: true,
      });
    axios
      .get("https://base-api-t3e66zpola-uk.a.run.app", {
        params: {
          usid: this.state.uid,
          service: "blockUser",
          params:
            "usid=" +
            this.state.uid +
            ",block=" +
            this.state.recoveryUId +
            ",type=unblock",
        },
        headers: { idtoken: this.state.idToken },
      })
      .then((response) => {
        if (response.data) {
          this.setState({
                rotating: false,
              });
          const myError = response.data.error;
          if (myError === "no error") {
             toast.success(<span><Translate>Friend has been recovered</Translate></span>, {
                id: "toast-success",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
            this.setState({
              message: myError,
            });
            this.successClose();
          } else {
            toast.error(<Translate>{response.data.error}</Translate>, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });
		  }
        } else {
            toast.error(<Translate>{response.data.error}</Translate>, {
                id: "toast-error",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
            }); }
      })
      .catch((error) => {
        console.log("Error req", error);
      });
  }

  UserDeleteAction(event) {
    event.preventDefault();
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
        id: "toast-loading",
        duration: 6000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
    });
    axios
      .get("https://base-api-t3e66zpola-uk.a.run.app", {
        params: {
          usid: this.state.uid,
          service: "blockUser",
          params:
            "usid=" +
            this.state.uid +
            ",block=" +
            this.state.DeleteUId +
            ",type=delete",
        },
        headers: { idtoken: this.state.idToken },
      })
      .then((response) => {
        if (response.data) {
          const myError = response.data.error;
          if (myError === "no error") {
            toast.success(<span><Translate>Friend has been deleted</Translate></span>, {
                id: "toast-success",
                style: {
                    maxWidth: 800,
                    fontSize: "14px",
                    borderRadius: "15px",
                },
              });

            this.setState({
              message: myError,
            });
            
            this.successClose();
          } else if (response.data.error !== "no error") {
            toast.error(<Translate>{response.data.error}</Translate>, {
                      id: "toast-error",
                      style: {
                          maxWidth: 800,
                          fontSize: "14px",
                          borderRadius: "15px",
                      },
                  });
		        }
          }
      })
      .catch((error) => {
        console.log("Error req", error);
      });
  }

  successClose() {
    window.location.reload(false);
  }

  handleDelete(event) {
    var res = String(event);
    const param = res.split(",");
    const Uid = param[0];
    const nickname = param[1];

    this.setState({
      deleteShow: true,
      DeleteUId: Uid,
      DeleteNickname: nickname,
    });
  }

  render() {
    if (this.state.pending) {
      return <Loader />;
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div
        className={
          this.state.isTableVisible ? "block-list" : "table-responsive-hide"
        }>
        <div className="friends-card">
          <div className="friends-content">
            <div className="row">
              <div className="col-md-12">
                <h6><Translate>Block List</Translate></h6>
              </div>
              <div className="col-md-12 ml-auto mr-auto">
                <div className="table-responsive">
                  <table className="table">
                    <tbody id="myTable">
                      {this.state?.blockFriends?.length> 0 ? <>
                        <tr>
                        <th><Translate>Avatar</Translate></th>
                        <th><Translate>Name</Translate></th>
                        <th><Translate>Followers</Translate></th>
                      </tr>
                      {this.state.blockFriends.map((f, index) => (
                        <tr key={index}>
                          <td>
                            <PeerImage
                              img={f.avatar}
                              classes="avatar rounded-circle"
                            />
                          </td>
                          <td>{f.value}</td>
                          <td>{f.followers}</td>
                          <td name={f.id} className="td-actions">
                            <Button
                              className="btn-gold"
                              onClick={() =>
                                this.handleRecovery([f.id, f.value])
                              } >
                              <Translate>Recovery</Translate>
                            </Button>
                            <Button
                              className="btn-dark"
                              onClick={() => this.handleDelete([f.id, f.value])} >
                              <Translate>Delete</Translate>
                            </Button>
                          </td>
                        </tr>
                      ))}
                      </>:  <small style={{color:'#fff'}}><Translate>No Blacklisted Friends Found</Translate></small>}  
                    </tbody>
                  </table>
                </div>
              </div>

              <Modal
                show={this.state.recoveryShow}
                onHide={() => this.handleRecoveryClose()}
                centered
                className="friends-popup" >
                <Modal.Header closeButton>
                  <Modal.Title><Translate>Recovery friend</Translate></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form onSubmit={this.UserRecoveryAction.bind(this)}>
                    <div className="recovery-user">
                      <p>
                      <Translate>Don't seek friends who make you comfortable, seek friends who inspire you to elevate yourself</Translate>
                      </p>
                      <br></br>
                      <Form.Group
                        controlId="formRecovery"
                        className="recovery-form" >
                        <Form.Label>
                        <Translate>Recovery</Translate>{" "}
                          <b style={{ color: "#8461dc" }}>
                            @{this.state.recoveryNickName}
                          </b>
                          ?
                        </Form.Label>
                      </Form.Group>
                      <Button 
                      type="submit" 
                      className="btn-gold"
                      disabled={this.state.rotating} >
                      {this.state.rotating ? (
                      <Spinner animation="border" />
                        ) : (
                          <Translate>Recovery</Translate>
                        )}
                      </Button>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>

              <Modal
                show={this.state.deleteShow}
                onHide={() => this.handleDeleteClose()}
                centered
                className="friends-popup" >
                <Modal.Header closeButton>
                  <Modal.Title><Translate>Delete friend</Translate></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form onSubmit={this.UserDeleteAction.bind(this)}>
                    <div className="block">
                      <p>
                      <Translate>Deleting</Translate>{" "}
                        <b style={{ color: "#8461dc" }}>
                          @{this.state.DeleteNickname}
                        </b>{" "}
                        <Translate>from</Translate> <b style={{ color: "#8461dc" }}>WEJE?</b>
                        <Translate>this person won't be notified and can't send you another request for helf a year</Translate>
                      </p>
                      <br></br>
                      <br></br>
                      <Form.Label>
                      <Translate>Delete</Translate>{" "}
                        <b style={{ color: "#8461dc" }}>
                          @{this.state.DeleteNickname}
                        </b>
                        ?
                      </Form.Label>
                      <br></br>
                      <Button type="submit" className="btn-dark">
                      <Translate>Delete</Translate>
                      </Button>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlockList;
